import React from "react";
import { imageLinks1 } from "../../Data/DataArray";
import { motion } from "framer-motion";

function OurClient() {
  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0.6,
      }}
      transition={{
        delay: 3.5,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      className="w-full flex items-center flex-col my-4 mb-24"
    >
      <div className="text-xl w-7/12 text-slate-300  text-center max-sm:w-11/12">
        Delivering creative freedom and media excellence to modern marketers
      </div>
      <div className="pt-10 pb-5  text-white w-full">
        <div className="flex flex-col pb-4  items-end justify-center h-full w-full py-3 overflow-hidden">
          <motion.div
            initial={{
              translateX: 0,
            }}
            transition={{
              duration: 60,
              type: "tween",
              repeat: Infinity,
            }}
            animate={{
              translateX: "50%",
            }}
            className="flex items-center"
          >
            {imageLinks1.map((image, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center min-w-28 h-20 mx-7"
                >
                  <div className="aspect-w-32 h-12 aspect-h-9">
                    <img
                      src={image}
                      alt="client_logo"
                      className="w-full h-full object-contain object-center"
                    />
                  </div>
                </div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default OurClient;
