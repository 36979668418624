import React from "react";
import { BsCursorFill } from "react-icons/bs";
import { useTypewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";

function WebDev() {
  const [text] = useTypewriter({
    words: ["Land Your dream job"],
    loop: 0,
    deleteSpeed: 100,
  });
  return (
    <div className="h-full  w-full ml-4 max-sm:w-full max-sm:hidden ">
      <div className="border-y mt-2 bg-[#2a2a2e] border-y-white/20 border-l rounded-tl-3xl border-l-white/20 h-full w-full flex items-end justify-end pt-2 pl-4 max-sm:pt-4 max-sm:px-6 max-sm:rounded-3xl">
        <div className="w-full h-full  rounded-tl-3xl bg-black flex flex-col items-center overflow-hidden relative">
          <div className="w-full pt-7 pb-3 px-10 ">
            <motion.div
              initial={{
                transform: "translate(0px, 0px)",
              }}
              transition={{
                delay: 0.8,
                duration: 2,
                type: "spring",
              }}
              whileInView={{
                transform: "translate(0px, 0px)",
              }}
            >
              <span className="text-white font-bold text-2xl">
                Skillkart.app
              </span>
            </motion.div>
          </div>
          <div className="w-full flex flex-col items-end justify-center pt-12 pb-16">
            <div className="w-full flex flex-col justify-center items-end">
              <div className="px-12 h-full text-white text-7xl font-bold rounded-l-full w-full flex items-center justify-start relative">
                {text}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDev;
