import React from "react";

export const Home = React.lazy(() => import("../Pages/Home/Home"));
export const Contact = React.lazy(() => import("../Pages/Contact/ContactUs"));
export const Carrer = React.lazy(() => import("../Pages/Carrer/Carrer"));
export const Services = React.lazy(() => import("../Pages/Services/Services"));
export const BlogDetail = React.lazy(() => import("../Pages/Blog/BlogDetail"));

// admin Routes

export const AddensureBlogs = React.lazy(() =>
  import("../Pages/Admin/Blogs/Blogs")
);
export const BlogCreation = React.lazy(() =>
  import("../Pages/Admin/Blogs/BlogCreation")
);
export const BlogEdit = React.lazy(() =>
  import("../Pages/Admin/Blogs/BlogEdit")
);
export const AdminMain = React.lazy(() => import("../Pages/Admin/AdminMain"));

// Other components

export const ThankYou = React.lazy(() => import("../Pages/ThankYou/ThankYou"));
export const NotFound = React.lazy(() => import("../Pages/404"));
export const About = React.lazy(() => import("../Pages/About/About"));
