import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import RouterConfig, { RouteConfig } from "./Routes/RouteConfig";
import { createBrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const getSiteData = async () => {
    const blogs = await axios
      .get("https://www.addensure.com/addensureapi/Blog/Blog.php")
      .then((result) => {
        dispatch({
          type: "blogs",
          payload: result.data,
        });
      });
    return blogs;
  };
  useEffect(() => {
    getSiteData();
  }, []);
  return (
    <>
      <RouterConfig />
    </>
  );
}

export default App;
