export const svgDataUrl =
  'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" fill="%23FFF"><path d="M161.65 109.51h-29.9c-8.21 0-15.46-5.49-17.5-13.44-3.53-13.71-3.56-36.8 20.27-64 4.66-5.32-1.93-12.99-7.92-9.22-40.46 25.48-60.92 54.53-60.92 86.66v95.96c0 7 5.67 12.67 12.67 12.67h83.29c7 0 12.67-5.67 12.67-12.67v-83.29c.01-6.99-5.67-12.67-12.66-12.67z"></path></svg>';
export const imageLinks1 = [
  ...require
    .context("../Assets/logos", false, /\.(jpg|png|gif|jpeg)$/)
    .keys()
    .map(require.context("../Assets/logos", false, /\.(jpg|png|gif|jpeg)$/)),
];

export const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

export const formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

export const seoKeyword = [
  { Title: "Keyword Research" },
  { Title: "On-Page SEO" },
  { Title: "Backlink Building" },
  { Title: "Monitoring and Analytics" },
  { Title: "Technical SEO" },
  { Title: "Quality Content" },
  { Title: "Local SEO" },
  { Title: "Mobile Optimization" },
  { Title: "User Engagement" },
  { Title: "Site Architecture" },
  { Title: "Site Security" },
  { Title: "Content Promotion" },
  { Title: "Voice Search Optimization" },
  { Title: "Site Speed Optimization" },
  { Title: "Long-Tail Keywords" },
  { Title: "Competitor Analysis" },
  { Title: "Linking Strategies" },
  { Title: "Social Proof" },
  { Title: "Structured Data Markup" },
  { Title: "Keyword Research" },
  { Title: "On-Page SEO" },
  { Title: "Backlink Building" },
  { Title: "Monitoring and Analytics" },
  { Title: "Technical SEO" },
  { Title: "Quality Content" },
  { Title: "Local SEO" },
  { Title: "Mobile Optimization" },
  { Title: "User Engagement" },
  { Title: "Site Architecture" },
  { Title: "Site Security" },
  { Title: "Content Promotion" },
  { Title: "Voice Search Optimization" },
  { Title: "Site Speed Optimization" },
  { Title: "Long-Tail Keywords" },
  { Title: "Competitor Analysis" },
  { Title: "Linking Strategies" },
  { Title: "Social Proof" },
  { Title: "Structured Data Markup" },
];

export const servicesWeProvide = [
  // {
  //   service: "Digital Marketing",
  //   description: "Tailored Strategies to Improve Your Online Presence.",
  // },
  {
    service: "Email Marketing",
    title: "Powerful Email Marketing Solutions Tailored for Your Success",
    description:
      "Email marketing is the most widely used online marketing tool used to generate online leads. Email marketing is the preferred choice of online marketers due to its reach and low cost. Email marketing is a very effective marketing tool to target a large base of targeted customers.",
  },
  {
    service: "Content Creation",
    description:
      "Creative design is an integral part of marketing . We combine creativity with strategy our passionate Graphic Design Team and Brand Strategy Makes Your Business Profitable.A creative designer's task is to communicate information in the best way possible. Creative designers research the audience so that they can design effective content for them.",
  },
  {
    service: "Website Development",
    title:
      "Crafting Digital Excellence: Comprehensive Website Solutions for Your Business",
    description:
      "We have a strong development team with good years of experience in Web store development. Our core expertise lies in Magento eCommerce, WooCommerce, OpenCart eCommerce and custom eCommerce website development. Experts in creating custom-designed eCommerce websites that are appealing, user-friendly and fits in your budget perfectly.",
  },
  {
    service: "App Development",
    title:
      "Crafting Digital Excellence: Comprehensive Website Solutions for Your Business",
    description:
      "Our App Development services are designed to transform your concepts into immersive, high-performance mobile applications that resonate with your target audience and elevate your digital presence",
  },
  {
    service: "Social Media Marketing",
    title:
      "Transform Your Brand's Presence: Unleash the Power of Social Media Marketing",
    description:
      "SMM is a highly reachable media marketing strategy that has become an interesting concept for individuals, agency/agencies and businesses looking for swift standing across quality client group. The social media marketing services offered by us will enable in acquiring potential clients.",
  },
  {
    service: "Media Planning and Buying",
    title:
      "Strategic Media Planning: Elevate Your Brand with Precision and Impact",
    description:
      "We have a strong development team with good years of experience in Web store development. Our core expertise lies in Magento eCommerce, WooCommerce, OpenCart eCommerce and custom eCommerce website development. Experts in creating custom-designed eCommerce websites that are appealing, user-friendly and fits in your budget perfectly.",
  },
  {
    service: "Search Engine Optimization",
    title:
      "Boost Your Visibility, Dominate Search Results: Expert SEO Services",
    description:
      "Boost your online visibility and drive organic traffic to your website with our expert Search Engine Optimization (SEO) services. We specialize in optimizing your website's content, enhancing its structure, and implementing strategic techniques to elevate your search engine rankings. Stay ahead of the competition and connect with your target audience effortlessly through our tailored SEO solutions",
  },
  {
    service: "SMS Marketing",
    title: "Unlock the Power of Instant Engagement with SMS Marketing",
    description: "Direct and Instant Communication with Your Audience.",
  },
];

export const testimonials = [
  {
    name: "Ramachandra Ganapathi",
    designation: "Netcore South Head Affiliate",
    content:
      "We developed a great partnership with Addensure Media and their dedication to delivering the KPI campaign to campaign is evident. Addensure media has given best of the results on branding and website traffic campaign like Oneplus, Sonyliv, News 18 and Maharashtra times. I appreciate their attention to details and flair to execution.",
    image:
      "https://www.addensure.com/images/media/testimonial/Ramachandra-Ganapathi.jpg",
  },
  {
    name: "Ankit Gupta",
    designation: "Sr. Manager Marketing Autoportal",
    content:
      "Addensure Media has been working with us for more than 3 years now as a strategic partner for delivering our campaigns. Their work on both branding and performance campaign has surpassed the expectation. They always focus on precise delivery with quality commitments.",
    image: "https://www.addensure.com/images/media/testimonial/Ankit-gupta.jpg",
  },
  {
    name: "Abhishek Gupta",
    designation: "Founder at free-thesis",
    content:
      "I am writing to convey my sincere appreciation for the remarkable work done by Addensure media delivered on their promises and exceeded our expectations. The team of Addensure media is professional, responsive and knowledgeable. Addensure Media helped us achieve our goals and grow our online presence.",
    image:
      "https://www.addensure.com/images/media/testimonial/1707482321559.jpeg",
  },
  {
    name: "Bijay Mishra",
    designation: "Digital Marketing Manager at paisabazar.com",
    content: `Addensure Media has exceeded my expectations. They're not just a bunch of yes-men; they're strategic thinkers who take the time to understand my business goals and target audience. They then develop data-driven campaigns that are tailored to achieve those goals.
     what I appreciate most about Addensure Media is their team. They're a group of talented and passionate individuals who are truly dedicated to their clients' success. They're always available to answer my questions and they're always willing to go the extra mile.
`,
    image:
      "https://www.addensure.com/images/media/testimonial/1517658490910.jpeg",
  },
];

export const servicesArray = [
  "Email Marketing",
  "Sms Marketing",
  "Search Engine Optimization",
  "Social Media Marketing",
  "Website Solutions",
  "App Development",
  "Creative Designing",
  "Content Marketing",
  "Media Planning & Buying",
];

export const servicesIndetail = [
  {
    intro: `Welcome to Addensure, where we transform ordinary emails into powerful marketing tools that drive engagement, boost brand awareness, and skyrocket your business growth. Our Email Marketing Services are designed to provide you with a comprehensive solution for reaching and connecting with your target audience effectively.
    `,
    ourService: "What Sets Our Email Marketing Services Apart?",
    whyUsQ: " Why Choose Us for Your Email Marketing Needs?",
    heading: "Powerful Email Marketing Solutions Tailored for Your Success",
    type: "Email Marketing",
    key: [
      {
        title: "Strategic Planning",
        description:
          "We begin by understanding your business goals, target audience, and unique selling propositions. Our team then crafts a tailored email marketing strategy to maximize your reach and impact.",
        align: "end",
      },
      {
        title: "List Building and Segmentation",
        description:
          "A well-curated email list is the cornerstone of successful campaigns. We help you build and segment your audience, ensuring that your messages are precisely targeted for higher engagement and conversion rates.",
        align: "start",
      },
      {
        title: "Compelling Content Creation",
        description:
          "Our team of skilled copywriters and designers create captivating email content that resonates with your audience. From attention-grabbing subject lines to persuasive calls to action, we ensure every element contributes to the overall success of your campaigns.",
        align: "end",
      },
      {
        title: "Responsive Design",
        description:
          "With the increasing use of mobile devices, we understand the importance of responsive design. Our emails are optimized to look stunning and function seamlessly across all devices, providing an exceptional user experience.",
        align: "start",
      },
      {
        title: "Automation for Efficiency",
        description:
          "Save time and enhance your customer journey with automated campaigns. From welcome emails to personalized follow-ups, we set up automated sequences that nurture leads and drive conversions.",
        align: "end",
      },
      {
        title: "Analytics and Reporting",
        description:
          "Gain valuable insights into your campaign performance with our detailed analytics and reporting tools. Track key metrics such as open rates, click-through rates, and conversions, allowing you to make data-driven decisions and continuously improve your strategy.",
        align: "start",
      },
      {
        title: "A/B Testing",
        description:
          "Experimentation is key to finding what resonates best with your audience. We conduct A/B tests on various elements of your campaigns, such as subject lines and content, to optimize for the highest impact.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we are dedicated to delivering results-driven email marketing services that align with your business objectives. Whether you are looking to nurture leads, promote products, or strengthen customer relationships, our team is committed to helping you achieve success through the power of email marketing.

          `,
      `Take your digital marketing to the next level with our Email Marketing Services. Contact us today to explore how we can tailor a solution to meet your unique business needs and propel your brand to new heights.
          `,
    ],
  },

  {
    intro: `Welcome to Addensure, where we harness the potential of short message service (SMS) marketing to revolutionize the way you engage with your audience.`,
    ourService: "Key Features of Our SMS Marketing Services:",
    whyUsQ: "Why Choose Addensure for SMS Marketing?",
    heading: "Unlocking the Power of SMS Marketing for Your Brand",
    type: "SMS Marketing",
    key: [
      {
        title: "Instant Reach",
        description:
          "SMS Marketing delivers your messages instantly, ensuring timely communication with your audience. Whether you're announcing promotions, sending alerts, or sharing updates, SMS ensures that your messages are received promptly.",
        align: "end",
      },
      {
        title: "High Open Rates",
        description:
          "SMS boasts one of the highest open rates among marketing channels. Reach your audience directly in their pockets, as text messages are typically read within minutes of being received, maximizing the chances of your message being seen.",
        align: "start",
      },
      {
        title: "Personalized Communication",
        description:
          "Tailor your messages to individual preferences and behaviors. Our SMS Marketing Services allow you to create personalized campaigns that resonate with your audience, fostering a deeper connection with your brand.",
        align: "end",
      },
      {
        title: "Opt-In and Compliance",
        description:
          "We prioritize compliance with regulations and industry standards. Our services include opt-in features, ensuring that your messages are sent to individuals who have expressed interest in receiving them, thereby enhancing the effectiveness of your campaigns.",
        align: "start",
      },
      {
        title: "Campaign Tracking and Analytics",
        description:
          "Stay informed about the performance of your SMS campaigns with our robust tracking and analytics tools. Monitor key metrics, such as delivery rates and click-through rates, to gain insights and refine your strategy for optimal results.",
        align: "end",
      },
      {
        title: "Promotions and Offers",
        description:
          "Drive sales and customer engagement by sending exclusive promotions and offers directly to your audience's phones. Encourage immediate action with time-sensitive deals to boost conversions.",
        align: "start",
      },
      {
        title: "Event Reminders and Alerts",
        description:
          "Keep your audience informed and engaged by sending event reminders, appointment confirmations, and timely alerts. Enhance customer satisfaction and reduce no-shows with proactive communication.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we understand the power of SMS Marketing in establishing direct and meaningful connections with your audience. Our experienced team is dedicated to creating and implementing SMS campaigns that align with your business goals, drive engagement, and deliver tangible results.`,
      `Elevate your marketing strategy with our SMS Marketing Services. Contact us today to explore how we can customize a solution to meet your unique business needs and propel your brand to new heights through the dynamic world of SMS communication.`,
    ],
  },
  {
    intro: `Welcome to Addensure, where we specialize in Search Engine Optimization (SEO) services to help your business thrive in the competitive digital landscape`,
    ourService: "Key Features of Our SEO Services:",
    whyUsQ: "Why Choose Addensure for SEO Services?",
    heading: `Boost Your Visibility, Dominate Search Results: Expert SEO Services
    `,
    type: "Search Engine Optimization",
    key: [
      {
        title: "Comprehensive Website Audit",
        description:
          "Our SEO experts conduct a thorough audit of your website to identify strengths, weaknesses, and opportunities for improvement. This includes analyzing on-page elements, site structure, and technical aspects to ensure your website is optimized for search engines.",
        align: "end",
      },
      {
        title: "Keyword Research and Strategy",
        description:
          "We meticulously research relevant keywords that align with your business objectives. Our team develops a strategic keyword plan to optimize your content, ensuring that your website ranks for the terms that matter most to your target audience.",
        align: "start",
      },
      {
        title: "On-Page Optimization",
        description:
          "Enhance the relevance and visibility of your web pages with on-page optimization. From meta tags and headers to keyword-rich content, we optimize every element to improve search engine rankings and provide a better user experience.",
        align: "end",
      },
      {
        title: "Quality Content Creation",
        description:
          "Engage your audience with valuable and relevant content. Our content specialists create compelling articles, blog posts, and other forms of content to establish your authority in your industry and attract organic traffic.",
        align: "start",
      },
      {
        title: "Link Building",
        description:
          "Build a strong and authoritative online presence with our effective link building strategies. We secure high-quality backlinks from reputable sources, boosting your website's credibility and improving its search engine rankings.",
        align: "end",
      },
      {
        title: "Local SEO Optimization",
        description:
          "For businesses targeting a local audience, we optimize your online presence to dominate local search results. This includes creating and managing local business listings, obtaining customer reviews, and implementing location-specific strategies.",
        align: "start",
      },
      {
        title: "Regular Monitoring and Reporting",
        description:
          "Stay informed about the performance of your SEO campaigns with our regular monitoring and reporting. We provide transparent insights into key metrics, such as keyword rankings, organic traffic, and conversion rates, allowing you to track the success of your SEO strategy.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we are committed to delivering results-driven SEO services that align with your business goals. Our experienced team combines industry expertise with the latest SEO best practices to ensure your website not only meets but exceeds the expectations of search engines.`,
      `Elevate your online presence and drive sustainable growth with our SEO services. Contact us today to explore how we can tailor a solution to meet your unique business needs and position your brand for success in the ever-evolving digital landscape.
      `,
    ],
  },
  {
    intro: `Welcome to Addensure, your partner in unlocking the full potential of Social Media Marketing (SMM)`,
    ourService: "Key Highlights of Our Social Media Marketing Services:",
    whyUsQ: "Why Choose Addensure for Social Media Marketing?",
    heading: "Elevate Your Brand on Social Media Platforms",
    type: "Social Media Marketing",
    key: [
      {
        title: "Strategic Planning and Campaign Management",
        description:
          "We begin by understanding your business goals and target audience. Our team then develops a customized social media strategy tailored to your brand. From content creation to campaign management, we ensure your message resonates effectively with your audience.",
        align: "end",
      },
      {
        title: "Content Creation and Curation",
        description:
          "Engaging content is at the heart of successful social media marketing. Our creative team produces visually appealing and compelling content that aligns with your brand identity. We curate content calendars to maintain consistency and relevance across your social media channels.",
        align: "start",
      },
      {
        title: "Platform Optimization",
        description:
          "Each social media platform has its nuances, and we understand the importance of platform-specific strategies. We optimize your profiles, ensuring they reflect your brand personality and resonate with your audience on platforms such as Facebook, Instagram, Twitter, LinkedIn, and more.",
        align: "end",
      },
      {
        title: "Community Engagement",
        description:
          "Building a community around your brand is crucial for long-term success. We foster meaningful interactions with your audience through comments, messages, and shares. By responding promptly and engaging authentically, we help strengthen your brand's relationship with its followers.",
        align: "start",
      },
      {
        title: "Paid Advertising Campaigns",
        description:
          "Amplify your reach and target specific demographics with our paid advertising campaigns. We strategically allocate budgets across platforms to maximize visibility and ensure your ads reach the right audience, driving traffic and conversions.",
        align: "end",
      },
      {
        title: "Influencer Marketing",
        description:
          "Leverage the influence of key personalities in your industry to enhance brand credibility and reach a wider audience. Our team identifies and collaborates with influencers who align with your brand values, creating authentic partnerships for maximum impact.",
        align: "start",
      },
      {
        title: "Analytics and Reporting",
        description:
          "Transparency is key. We provide detailed analytics and reports to measure the success of your social media campaigns. Track key performance indicators (KPIs) such as engagement rates, follower growth, and conversion metrics to continually refine and optimize your strategy.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we understand that social media is more than just posting content—it's about building connections and driving business results. Our experienced team combines creativity with data-driven strategies to ensure your brand stands out in the crowded social media landscape.`,
      `Elevate your social media presence and achieve tangible results with our Social Media Marketing Services. Contact us today to explore how we can tailor a solution to meet your unique business needs and propel your brand to new heights on social media.
      `,
    ],
  },

  // Structure for Website Development service
  {
    intro: `Welcome to Addensure, where we bring your digital vision to life through innovative and customized Website Development services`,
    ourService: "Our Website Development Services:",
    whyUsQ: "Why Choose Addensure for Website Development?",
    heading: "Crafting Exceptional Websites for Your Digital Presence",
    type: "Website Development",
    key: [
      {
        title: "Custom Web Development",
        description:
          "We understand that each business is unique. Our custom web development services ensure that your website is tailored to meet your specific needs and objectives. From concept to execution, we work closely with you to create a website that stands out in today's competitive digital landscape.",
        align: "end",
      },
      {
        title: "Responsive Design",
        description:
          "In a world where users access websites from various devices, responsive design is crucial. We prioritize creating websites that adapt seamlessly to different screen sizes, ensuring a consistent and user-friendly experience across desktops, tablets, and smartphones.",
        align: "start",
      },
      {
        title: "E-commerce Development",
        description:
          "For businesses looking to establish or expand their online presence, our E-commerce Development services provide a robust platform. We design and develop secure and feature-rich online stores, integrating payment gateways and user-friendly interfaces to optimize the online shopping experience.",
        align: "end",
      },
      {
        title: "Content Management Systems (CMS)",
        description:
          "Take control of your website's content with our CMS solutions. Whether you prefer WordPress, Joomla, or Drupal, we implement user-friendly systems that empower you to update and manage your website content effortlessly, even with minimal technical expertise.",
        align: "start",
      },
      {
        title: "Web Application Development",
        description:
          "Elevate your online capabilities with our web application development services. We design and develop custom web applications that streamline processes, enhance user engagement, and provide unique solutions to address your business challenges.",
        align: "end",
      },
      {
        title: "Search Engine Optimization (SEO) Integration",
        description:
          "A visually appealing website is essential, but visibility is paramount. Our development process incorporates SEO best practices to ensure your website is not only aesthetically pleasing but also optimized for search engines, driving organic traffic and improving rankings.",
        align: "start",
      },
      {
        title: "Maintenance and Support",
        description:
          "We don't just build websites; we ensure they thrive over time. Our maintenance and support services cover everything from routine updates to troubleshooting, ensuring your website remains secure, up-to-date, and functions smoothly.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we combine technical expertise with a passion for creativity to deliver websites that go beyond expectations. Our commitment to quality, innovation, and client satisfaction sets us apart as your trusted partner in the digital realm.`,
      `Transform your digital presence with our Website Development services. Contact us today to discuss how we can turn your vision into a dynamic and functional website that captivates your audience and drives your online success.
      `,
    ],
  },

  // Structure for App Development service
  {
    intro: `Welcome to Addensure, where innovation meets functionality in the world of App Development`,
    ourService: "Our Comprehensive App Development Offerings:",
    whyUsQ: "Why Choose Addensure for App Development?",
    heading: "Transforming Concepts into High-Performance Mobile Applications",
    type: "App Development",
    key: [
      {
        title: "Platform Expertise",
        description:
          "Harness the power of mobile platforms with our specialized iOS and Android App Development services. Whether you're targeting a specific platform or both, our team ensures your app is optimized for performance, user experience, and compatibility.",
        align: "end",
      },
      {
        title: "Customized Solutions",
        description:
          "We understand that each business has unique needs. Our custom app development approach involves a collaborative process, working closely with you to conceptualize, design, and develop an application that aligns seamlessly with your brand identity and objectives.",
        align: "start",
      },
      {
        title: "User-Centric Design",
        description:
          "A successful app begins with a user-centric design. Our team prioritizes intuitive interfaces and engaging user experiences, ensuring that your app not only meets functional requirements but also delights users, fostering satisfaction and loyalty.",
        align: "end",
      },
      {
        title: "Cross-Platform Development",
        description:
          "Extend your reach with our cross-platform development services. Utilizing frameworks like React Native and Flutter, we create apps that run seamlessly across various platforms, providing cost-efficient solutions without compromising on performance.",
        align: "start",
      },
      {
        title: "E-commerce App Excellence",
        description:
          "Capitalize on the mobile commerce boom with our E-commerce App Development expertise. We craft secure and feature-rich mobile shopping experiences that enhance user convenience and drive increased engagement and conversions.",
        align: "end",
      },
      {
        title: "Integration of Advanced Technologies",
        description:
          "Stay ahead of the curve by integrating cutting-edge technologies into your app. Whether it's Augmented Reality (AR), Virtual Reality (VR), or Internet of Things (IoT), we explore and implement advanced features to keep your app at the forefront of innovation.",
        align: "start",
      },
      {
        title: "Thorough Testing and Quality Assurance",
        description:
          "Quality is paramount in app development. Our rigorous testing and quality assurance processes identify and address potential issues, ensuring a stable, reliable, and bug-free application before it reaches your users.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we are not just developers; we are arc hitects of digital experiences. Our commitment to delivering innovative, high-quality apps sets us apart. As your dedicated app development partner, we bring your vision to life while prioritizing your goals, timelines, and user satisfaction.`,
    ],
  },
  {
    intro: `Welcome to Addensure, where creativity meets functionality in our comprehensive Creative Design services`,
    ourService: "Our Creative Designing Services:",
    whyUsQ: "Why Choose Addensure for Creative Designing?",
    heading: "Turning Ideas into Visually Compelling Designs",
    type: "Creative Designing",
    key: [
      {
        title: "Custom Graphic Design",
        description:
          "Unleash the power of visual storytelling with our custom graphic design services. From eye-catching logos to captivating marketing collateral, our designers bring your brand to life with unique and impactful visuals that leave a lasting impression.",
        align: "end",
      },
      {
        title: "Branding and Identity",
        description:
          "Establish a strong brand presence with our branding and identity services. We work closely with you to define and create a distinctive brand identity that reflects your values, resonates with your audience, and sets you apart in a competitive landscape.",
        align: "start",
      },
      {
        title: "Web and User Interface (UI) Design",
        description:
          "Elevate your online presence with our web and UI design services. Our designers craft visually appealing and user-friendly websites and interfaces that not only showcase your content effectively but also enhance the overall user experience.",
        align: "end",
      },
      {
        title: "Print Design",
        description:
          "Make a statement in the physical world with our print design services. From brochures and business cards to posters and banners, we create print materials that align with your brand aesthetics and convey your message with impact.",
        align: "start",
      },
      {
        title: "Packaging Design",
        description:
          "Stand out on the shelves with our innovative packaging design services. We create packaging solutions that not only protect your products but also tell a compelling story, enticing customers and enhancing brand recognition.",
        align: "end",
      },
      {
        title: "Social Media Graphics",
        description:
          "Boost your social media presence with attention-grabbing graphics. Our designers create visually engaging social media posts, cover images, and ad graphics that align with your brand and drive user engagement across various platforms.",
        align: "start",
      },
      {
        title: "Illustrations and Infographics",
        description:
          "Bring your ideas to life with our illustration and infographic services. Whether you need custom illustrations for your website or infographics to convey complex information in a visually appealing manner, we've got you covered.",
        align: "end",
      },
    ],
    whyus: [
      `At Addensure, we believe that effective design is a blend of creativity and strategy. Our dedicated team of designers goes beyond aesthetics, focusing on creating designs that communicate your message, strengthen your brand, and leave a lasting impression on your audience.`,
    ],
  },
  {
    type: "Media Planning & Buying",
    intro: `Welcome to Addensure, where we specialize in strategic Media Planning & Buying services designed to maximize the impact of your advertising campaigns. Our team of seasoned professionals takes a data-driven approach to ensure that your brand reaches the right audience through the most effective channels, optimizing your media spend for optimal results.`,
    ourService: "Our Media Planning & Buying Services:",
    heading: `Strategic Media Planning: Elevate Your Brand with Precision and Impact
    `,
    key: [
      {
        title: "Comprehensive Media Strategy",
        description:
          "We begin by understanding your business objectives, target audience, and market dynamics. Our experts develop a comprehensive media strategy that aligns with your goals, ensuring a well-coordinated and impactful approach across various channels.",
        align: "end",
      },
      {
        title: "Audience Analysis",
        description:
          "Knowing your audience is key to successful media planning. We conduct in-depth audience analysis to identify demographics, behaviors, and preferences, allowing us to tailor media plans that resonate with your target market and drive engagement.",
        align: "start",
      },
      {
        title: "Channel Selection",
        description:
          "Our team carefully selects the most relevant and high-performing media channels for your brand. Whether it's traditional outlets such as television, radio, and print, or digital platforms including social media, display advertising, and search engines, we tailor our approach to meet your unique needs.",
        align: "end",
      },
      {
        title: "Budget Optimization",
        description:
          "We understand the importance of maximizing your media budget. Our experts optimize your media spend to achieve the highest possible return on investment (ROI), ensuring that your advertising dollars are strategically allocated for maximum impact and efficiency.",
        align: "start",
      },
      {
        title: "Negotiation and Buying",
        description:
          "Leveraging our industry relationships, we negotiate the best possible rates and placements for your advertisements. Whether securing prime time slots on television, premium positions in print publications, or targeted placements in the digital realm, we prioritize value for your investment.",
        align: "end",
      },
      {
        title: "Campaign Monitoring and Optimization",
        description:
          "Our commitment doesn't end with planning and buying. We continuously monitor campaign performance, analyzing key metrics to identify opportunities for optimization. From adjusting targeting parameters to refining creative elements, we ensure your campaign evolves for maximum effectiveness.",
        align: "start",
      },
      {
        title: "Post-Campaign Analysis",
        description:
          "After the campaign concludes, we conduct a thorough post-campaign analysis to measure success against predefined KPIs. This analysis provides valuable insights for future planning, allowing us to refine strategies and enhance performance in subsequent campaigns.",
        align: "end",
      },
    ],
    whyUsQ: "Why Choose Addensure for Media Planning & Buying?",
    whyus: [
      `At Addensure, we believe that successful media planning is a dynamic process that requires expertise, creativity, and adaptability. Our team combines years of industry experience with cutting-edge tools to deliver media strategies that not only meet but exceed your advertising objectives.`,
    ],
  },
];

//     email: {
//       data: [
//         {
//           title: "Strategic Planning",
//           description:
//             "We begin by understanding your business goals, target audience, and unique selling propositions. Our team then crafts a tailored email marketing strategy to maximize your reach and impact.",
//           align: "end",
//         },
//         {
//           title: "List Building and Segmentation",
//           description:
//             "A well-curated email list is the cornerstone of successful campaigns. We help you build and segment your audience, ensuring that your messages are precisely targeted for higher engagement and conversion rates.",
//           align: "start",
//         },
//         {
//           title: "Compelling Content Creation",
//           description:
//             "Our team of skilled copywriters and designers create captivating email content that resonates with your audience. From attention-grabbing subject lines to persuasive calls to action, we ensure every element contributes to the overall success of your campaigns.",
//           align: "end",
//         },
//         {
//           title: "Responsive Design",
//           description:
//             "With the increasing use of mobile devices, we understand the importance of responsive design. Our emails are optimized to look stunning and function seamlessly across all devices, providing an exceptional user experience.",
//           align: "start",
//         },
//         {
//           title: "Automation for Efficiency",
//           description:
//             "Save time and enhance your customer journey with automated campaigns. From welcome emails to personalized follow-ups, we set up automated sequences that nurture leads and drive conversions.",
//           align: "end",
//         },
//         {
//           title: "Analytics and Reporting",
//           description:
//             "Gain valuable insights into your campaign performance with our detailed analytics and reporting tools. Track key metrics such as open rates, click-through rates, and conversions, allowing you to make data-driven decisions and continuously improve your strategy.",
//           align: "start",
//         },
//         {
//           title: "A/B Testing",
//           description:
//             "Experimentation is key to finding what resonates best with your audience. We conduct A/B tests on various elements of your campaigns, such as subject lines and content, to optimize for the highest impact.",
//           align: "end",
//         },
//       ],
//       whyus: [
//         `At Addensure, we are dedicated to delivering results-driven email marketing services that align with your business objectives. Whether you are looking to nurture leads, promote products, or strengthen customer relationships, our team is committed to helping you achieve success through the power of email marketing.
//       `,
//       ],
//     },

// ];
