import React from "react";
import { testimonials } from "../../Data/DataArray";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
function Testmonial() {
  return (
    <div>
      <div className="w-full flex flex-col items-center mt-8 py-16 min-h-screen max-sm:pt-8 max-[1060px]:pb-12 max-[1060px]:min-h-0">
        <motion.div
          initial={{
            fontSize: "24px",
            opacity: 0.1,
            translateY: 100,
          }}
          transition={{
            duration: 1,
            delay: 0.1,

            type: "spring",
          }}
          viewport={{
            once: true,
          }}
          whileInView={{
            fontSize: "66px",
            opacity: 1,
            translateY: 0,
          }}
          className="font-bold pt-7 px-16 sticky top-14 text-black rounded-full max-sm:px-8"
        >
          <span className="z-10 text-white relative max-md:text-4xl max-sm:text-3xl">
            People 💓 Our Work
          </span>
        </motion.div>
        <div className="flex items-center justify-start"></div>
        <div className="flex items-start px-20 pt-10 min-h-0  relative max-lg:px-8 max-sm:flex-col max-sm:px-2">
          <div className="h-full absolute top-0 bottom-0 right-0 left-0 w-full bg-gradient-to-b from-black/5 to-black/30"></div>
          <div className="absolute -bottom-20 right-0 left-0  flex items-center justify-center w-full">
            <Link to="/in/aboutus" target="_blank">
              <div className="py-3 px-8 bg-white rounded-full font-bold">
                Know more about us
              </div>
            </Link>{" "}
          </div>
          <div className="w-1/2 max-[850px]:w-full">
            <div className="w-full flex flex-wrap items-start">
              {testimonials.slice(0, 2).map((testimonial, index) => (
                <div
                  key={index}
                  className="p-2 w-full max-md:w-full max-[550px]:w-full"
                >
                  <motion.div
                    className="bg-white/5 w-full py-4 px-6 rounded-lg"
                    initial={{
                      scale: 0.7,
                    }}
                    transition={{
                      duration: 0.6,
                      delay: 0.1,
                    }}
                    whileInView={{
                      scale: 1,
                    }}
                  >
                    <div className="flex items-center">
                      <div className="w-11 h-11 mr-3">
                        <img
                          src={testimonial.image}
                          alt="_person"
                          className="w-full h-full rounded"
                        />
                      </div>
                      <div>
                        <span className="text-xl text-white font-bold">
                          {testimonial.name}
                        </span>
                        <div className="text-sm text-white/60">
                          {testimonial.designation}
                        </div>
                      </div>
                    </div>
                    <div className="text-base  text-white/80 mt-3">
                      {testimonial.content}
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/2 max-md:w-full max-[850px]:hidden max-sm:flex  max-sm:w-full">
            <div className="w-full flex flex-col items-center max-sm:flex-col max-sm:items-start">
              {testimonials.slice(2, 4).map((testimonial, index) => (
                <div
                  key={index}
                  className="p-2 w-full max-md:w-full max-[550px]:w-full"
                >
                  <motion.div
                    className="bg-white/5 w-full py-4 px-6 rounded-lg"
                    initial={{
                      scale: 0.7,
                    }}
                    transition={{
                      duration: 0.6,
                      delay: 0.1,
                    }}
                    whileInView={{
                      scale: 1,
                    }}
                  >
                    <div className="flex items-center">
                      <div className="w-11 h-11 mr-3">
                        <img
                          src={testimonial.image}
                          alt="_person"
                          className="w-full h-full rounded"
                        />
                      </div>
                      <div>
                        <span className="text-xl text-white font-bold">
                          {testimonial.name}
                        </span>
                        <div className="text-sm text-white/60">
                          {testimonial.designation}
                        </div>
                      </div>
                    </div>
                    <div className="text-base  text-white/80 mt-3">
                      {testimonial.content}
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="w-1/3 max-md:w-1/2 max-[650px]:hidden">
            <div className="w-full flex flex-col items-center">
              {testimonials.slice(4, 7).map((testimonial, index) => (
                <div key={index} className="p-2">
                  <motion.div
                    className="bg-white/5 w-full py-4 px-6 rounded-lg"
                    initial={{
                      scale: 0.7,
                    }}
                    transition={{
                      duration: 0.6,
                      delay: 0.1,
                    }}
                    whileInView={{
                      scale: 1,
                    }}
                  >
                    <span className="text-xl text-white font-bold">
                      {testimonial.name}
                    </span>
                    <div className="text-sm text-white/60">
                      {testimonial.designation}
                    </div>
                    <div className="text-base  text-white/80 mt-3">
                      {testimonial.content}
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/3 max-md:hidden">
            <div className="w-full flex flex-col items-center">
              {testimonials.slice(9, 12).map((testimonial, index) => (
                <div key={index} className="p-2">
                  <motion.div
                    className="bg-white/5 w-full py-4 px-6 rounded-lg"
                    initial={{
                      scale: 0.7,
                    }}
                    transition={{
                      duration: 0.6,
                      delay: 0.1,
                    }}
                    whileInView={{
                      scale: 1,
                    }}
                  >
                    <span className="text-xl text-white font-bold">
                      {testimonial.name}
                    </span>
                    <div className="text-sm text-white/60">
                      {testimonial.designation}
                    </div>
                    <div className="text-base  text-white/80 mt-3">
                      {testimonial.content}
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Testmonial;
