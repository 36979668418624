import React from "react";
import { motion } from "framer-motion";
import { seoKeyword } from "../../Data/DataArray";

function Seo() {
  return (
    <div className="flex flex-col pb-4 border-b border-b-white/10  items-end justify-start h-full max-md:h-44 w-full py-3 overflow-hidden">
      <motion.div
        initial={{
          translateY: 10,
        }}
        transition={{
          duration: 3,
          type: "spring",
          delay: 0.3,
        }}
        whileInView={{
          translateY: -100,
        }}
        className="flex items-start flex-wrap w-full justify-center  px-2"
      >
        {seoKeyword.map((keyword, index) => (
          <motion.div className="flex items-center justify-center m-1 text-sm py-1.5 px-4 rounded-md bg-black text-white/70 font-semibold border border-white/20">
            {keyword.Title}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default Seo;
