import React from "react";
import { FaFacebook, FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import {
  FaInstagram,
  FaSnapchatSquare,
  FaDribbble,
  FaPinterest,
  FaRedditAlien,
  FaTumblrSquare,
  FaGithub,
  FaYoutube,
  FaSpotify,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { FcGoogle } from "react-icons/fc";

function SocialIcons() {
  const socialMediaIcons = [
    { icon: FaFacebook, color: "#0866ff", name: "Facebook" },
    { icon: FaSquareXTwitter, color: "#1da1f2", name: "Twitter" },
    { icon: FaInstagram, color: "#e1306c", name: "Instagram" },
    { icon: FaLinkedin, color: "#0077b5", name: "LinkedIn" },
    { icon: FaSnapchatSquare, color: "#fffc00", name: "Snapchat" },
    { icon: FcGoogle, color: "#4285f4", name: "Google" },
    { icon: FaDribbble, color: "#ea4c89", name: "Dribbble" },
    { icon: FaPinterest, color: "#bd081c", name: "Pinterest" },
    { icon: FaRedditAlien, color: "#ff4500", name: "Reddit" },
    { icon: FaTumblrSquare, color: "#35465d", name: "Tumblr" },
    { icon: FaYoutube, color: "#ff0000", name: "YouTube" },
    { icon: FaSpotify, color: "#1db954", name: "Spotify" },
    { icon: FaFacebook, color: "#0866ff", name: "Facebook" },
    { icon: FaSquareXTwitter, color: "#1da1f2", name: "Twitter" },
    { icon: FaInstagram, color: "#e1306c", name: "Instagram" },
    { icon: FaLinkedin, color: "#0077b5", name: "LinkedIn" },
    { icon: FaSnapchatSquare, color: "#fffc00", name: "Snapchat" },
    { icon: FcGoogle, color: "#4285f4", name: "Google" },
    { icon: FaDribbble, color: "#ea4c89", name: "Dribbble" },
    { icon: FaPinterest, color: "#bd081c", name: "Pinterest" },
    { icon: FaRedditAlien, color: "#ff4500", name: "Reddit" },
    { icon: FaTumblrSquare, color: "#35465d", name: "Tumblr" },
    { icon: FaYoutube, color: "#ff0000", name: "YouTube" },
    { icon: FaSpotify, color: "#1db954", name: "Spotify" },
  ];
  return (
    <div className="flex flex-col pb-4 border-b border-b-white/10  items-end justify-center h-full w-full py-3 overflow-hidden">
      <motion.div
        initial={{
          translateX: 0,
        }}
        transition={{
          duration: 20,
          type: "tween",
          repeat: Infinity,
        }}
        whileInView={{
          translateX: "50%",
        }}
        className="flex items-center"
      >
        {socialMediaIcons.map((socials, index) => (
          <motion.div className="flex items-center justify-center py-1 px-2">
            <socials.icon color={socials.color} fontSize={55} />
          </motion.div>
        ))}
      </motion.div>
      <motion.div
        initial={{
          translateX: 0,
        }}
        transition={{
          duration: 10,
          delay: 0.3,
          repeat: Infinity,
          type: "spring",
        }}
        whileInView={{
          translateX: "50%",
        }}
        className="flex items-center my-4"
      >
        {socialMediaIcons.map((socials, index) => (
          <motion.div className="flex items-center justify-center py-1 px-2">
            <socials.icon color={socials.color} fontSize={55} />
          </motion.div>
        ))}
      </motion.div>
      <motion.div
        initial={{
          translateX: 0,
        }}
        transition={{
          duration: 10,
          type: "spring",
          repeat: Infinity,
        }}
        animate={{
          translateX: "50%",
        }}
        className="flex items-center"
      >
        {socialMediaIcons.map((socials, index) => (
          <motion.div className="flex items-center justify-center py-1 px-2">
            <socials.icon color={socials.color} fontSize={55} />
          </motion.div>
        ))}
      </motion.div>
    </div>
    // <div className="w-full h-4/6 pb-10 flex items-end justify-center relative max-sm:h-[400px] max-sm:w-full max-sm:px-10">
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       delay: 0.2,
    //       duration: 0.5,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(200px, -250px)`,
    //     }}
    //   >
    //     <FaFacebook color="#0866ff" fontSize={55} />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1,
    //       delay: 0.2,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(-40px, -270px)`,
    //     }}
    //   >
    //     <FaSquareXTwitter color="white" fontSize={55} />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       delay: 0.2,
    //       duration: 0.5,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(100px, -150px)`,
    //     }}
    //   >
    //     <FaLinkedin color="blue" fontSize={55} />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       size: 28,
    //       transform: `translate(0px, 0px)`,
    //     }}
    //     transition={{
    //       duration: 1,
    //       delay: 0.2,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(-120px, -150px)`,
    //     }}
    //   >
    //     <FaSnapchatSquare color="yellow" fontSize={55} />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1.5,
    //       delay: 0.5,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(0px, 0px)`,
    //     }}
    //   >
    //     <FcGoogle fontSize={55} />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1.5,
    //       delay: 0,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(-180px, -250px)`,
    //     }}
    //   >
    //     <FaDribbble fontSize={55} color="#f08baf" />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1.5,
    //       delay: 0,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(0px, -180px)`,
    //     }}
    //   >
    //     <FaPinterest fontSize={55} color="red" />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1.5,
    //       delay: 0,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(0px, -80px)`,
    //     }}
    //   >
    //     <FaRedditAlien fontSize={55} color="#f63f04" />
    //   </motion.div>
    //   <motion.div
    //     className="absolute"
    //     initial={{
    //       // opacity: 0,
    //       transform: `translate(0px, 0px)`,
    //       size: 28,
    //     }}
    //     transition={{
    //       duration: 1.5,
    //       delay: 0,
    //       type: "spring",
    //     }}
    //     whileInView={{
    //       opacity: 1,
    //       fontSize: 55,
    //       transform: `translate(100px, -250px)`,
    //     }}
    //   >
    //     <FaTumblrSquare fontSize={55} color="white" />
    //   </motion.div>
    // </div>
  );
}

export default SocialIcons;
