import React from "react";
import { motion } from "framer-motion";
import { useTypewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";

function HomeBanner() {
  const [text] = useTypewriter({
    words: ["advertising", "Marketing", "Content Creation", "SEO"],
    loop: 0,
    delaySpeed: 6000,
    deleteSpeed: 100,
  });

  return (
    <div className="w-full flex flex-col items-center justify-center pt-20 pb-16 max-sm:pt-14">
      <div className="w-10/12 flex flex-col justify-center items-center max-lg:w-11/12">
        <motion.div
          onClick={() => {
            window.location.assign("/in/contactus");
          }}
          initial={{
            width: "450px",
            height: "70px",
            position: "absolute",
            top: "180px",
            opacity: 0,
          }}
          transition={{
            delay: 0.5,
            duration: 1.5,

            type: "spring",
          }}
          animate={{
            scale: 1,
            position: "relative",
            top: 0,
            opacity: 1,
            height: "45px",
            width: "280px",
          }}
          className="rounded-full btn-grad mb-3 w-[250px] h-10 bg-gradient-to-r p-0.5 from-green-400 via-yellow-500 to-green-400 text-white"
        >
          <motion.div
            initial={{
              width: 0,
            }}
            transition={{
              duration: 1.5,
            }}
            animate={{
              width: "100%",
            }}
            className="bg-[#052425] h-full rounded-full w-full text-center flex items-center justify-center overflow-hidden"
          >
            <motion.span
              initial={{
                opacity: 0,
                translateX: -100,
              }}
              transition={{
                delay: 2,
                duration: 1,
              }}
              animate={{
                opacity: 1,
                translateX: 0,
              }}
            >
              {" "}
              Get a free consultation now 🥰
            </motion.span>
          </motion.div>
        </motion.div>
        <motion.h1
          initial={{
            opacity: 0,
            scale: 0.7,
          }}
          transition={{
            delay: 2.5,
            duration: 0.6,
          }}
          whileInView={{
            opacity: 1,
            scale: 1,
          }}
          viewport={{
            once: true,
          }}
          // animate={{
          //   opacity: 1,
          // }}
          className="text-7xl font-bold text-white text-center mt-4 relative max-sm:text-4xl max-sm:text-left  max-[670px]:text-5xl"
        >
          <span className="mx-1 text-green-400 max-sm:hidden">&ldquo;</span>
          Elevate your brand with innovative{" "}
          <span className=" text-green-400">{text}</span> solutions
          <span className="mx-1 text-green-400 max-sm:hidden">&rdquo;</span>
        </motion.h1>
        <motion.h2
          initial={{
            opacity: 0,
          }}
          transition={{
            delay: 3,
          }}
          animate={{
            opacity: 1,
          }}
          className="w-8/12 text-base text-center text-slate-300 my-5 max-sm:text-base max-sm:w-full max-sm:text-left"
        >
          Welcome to Addensure, where we transform visions into compelling
          stories that help brands stand out in a crowded digital environment
        </motion.h2>

        <Link to="/in/contactus">
          <motion.div
            initial={{
              opacity: 0,
            }}
            transition={{
              delay: 3,
              duration: 1,
            }}
            animate={{
              opacity: 1,
            }}
            className="flex items-center bg-[#6aa12d] text-base font-semibold mt-5 rounded-full border border-white"
          >
            <div className="bg-white text-black py-4 px-8 rounded-full cursor-pointer z-10 relative">
              Get Started
            </div>
            <motion.div
              initial={{
                display: "none",
                translateX: -100,
                width: 0,
              }}
              transition={{
                delay: 3,
                duration: 0.5,
              }}
              animate={{
                display: "flex",
                translateX: 0,
                width: "128px",
              }}
              className="w-32 h-10 rounded-full text-black cursor-pointer flex items-center justify-center"
            >
              <motion.span
                initial={{
                  display: "none",
                }}
                transition={{
                  delay: 3.5,
                }}
                animate={{
                  display: "flex",
                }}
                className="w-full h-full flex items-center justify-center"
              >
                Contact us
              </motion.span>
            </motion.div>
          </motion.div>
        </Link>
      </div>
    </div>
  );
}

export default HomeBanner;
