import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../Assets/images/logo.png";

function Header() {
  const url = window.location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      transition={{
        delay: `${url == "/" ? 2.5 : 0}`,
      }}
      animate={{
        opacity: 1,
      }}
      className="flex items-center py-4 px-8 justify-between bg-black z-50 sticky top-0 max-sm:pt-6 max-sm:sticky max-sm:top-0"
    >
      <Link to="/">
        <div
          onClick={() => {
            window.location.assign("/");
          }}
        >
          <img src={Logo} alt="addenure Logo" className="w-36" />
        </div>
      </Link>
      <div
        className="hidden max-sm:flex p-1 border border-white/20"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <RxHamburgerMenu color="white" fontSize={21} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{
              height: 0,
            }}
            transition={{
              duration: 1,

              type: "tween",
            }}
            animate={{
              height: "100vh",
            }}
            className="h-screen w-screen fixed bg-black top-0 bottom-0 right-0 left-0 py-6 px-8"
          >
            <div className="flex items-center justify-between">
              <div
                onClick={() => {
                  window.location.assign("/");
                }}
              >
                <img src={Logo} alt="addenure Logo" className="w-36" />
              </div>
              <div
                className="hidden max-sm:flex p-1 border border-white/20 rounded"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <AiOutlineClose color="white" fontSize={21} />
              </div>
            </div>
            <div className="pt-14">
              <div className="text-white/80 text-base">
                <Link to="/">
                  {" "}
                  <motion.div
                    className="mx-6 cursor-pointer py-2 my-1.5"
                    initial={{
                      opacity: 0,
                    }}
                    transition={{
                      delay: 0.6,
                      type: "spring",
                    }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    Home
                  </motion.div>
                </Link>

                <motion.div
                  onClick={() => {
                    if (window.location.pathname == "/") {
                      let scrollDownTo =
                        document.getElementsByClassName("services")[0];
                      scrollDownTo.scrollIntoView({
                        behavior: "smooth",
                      });
                      return setIsOpen(false);
                    } else {
                      window.location.assign("/?ref=services");
                    }
                  }}
                  className="mx-6 cursor-pointer py-2 my-1.5"
                  initial={{
                    opacity: 0,
                  }}
                  transition={{
                    delay: 0.7,
                    type: "spring",
                  }}
                  animate={{
                    opacity: 1,
                  }}
                >
                  Services
                </motion.div>

                <Link to="/in/careers">
                  <motion.div
                    className="mx-6 cursor-pointer py-2 my-1.5"
                    initial={{
                      opacity: 0,
                    }}
                    transition={{
                      delay: 0.8,
                      type: "spring",
                    }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    Career
                  </motion.div>
                </Link>
                <Link to="/in/contactus">
                  <motion.div
                    className="mx-6 cursor-pointer py-2 my-1.5"
                    initial={{
                      opacity: 0,
                    }}
                    transition={{
                      delay: 0.9,
                      type: "spring",
                    }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    Contact us
                  </motion.div>
                </Link>
              </div>
              <div className="text-base font-bold text-slate-300 mt-6">
                <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
                  <motion.div
                    className="bg-[#28453f] mx-4 py-4 px-6 rounded mb-3 cursor-pointer"
                    initial={{
                      opacity: 0,
                    }}
                    transition={{
                      delay: 1,
                      type: "spring",
                    }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    Advertiser
                  </motion.div>
                </Link>
                <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
                  <motion.div
                    className="bg-[#6aa12d] text-black mx-4 py-4 px-6 rounded mb-3 cursor-pointer"
                    initial={{
                      opacity: 0,
                    }}
                    transition={{
                      delay: 1.1,
                      type: "spring",
                    }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    Publisher
                  </motion.div>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex text-white/80 items-center text-sm max-lg:hidden">
        <Link to="/">
          <div className="mx-6 cursor-pointer">Home</div>
        </Link>

        <div
          onClick={() => {
            if (window.location.pathname == "/") {
              let scrollDownTo = document.getElementsByClassName("services")[0];
              scrollDownTo.scrollIntoView({
                behavior: "smooth",
              });
              return setIsOpen(false);
            } else {
              window.location.assign("/?ref=services");
            }
          }}
          className="mx-6 cursor-pointer"
        >
          Services
        </div>

        <Link to="/in/careers">
          <div className="mx-6 cursor-pointer">Career</div>
        </Link>
        <Link to="/in/contactus">
          <div className="mx-6 cursor-pointer">Contact us</div>
        </Link>
      </div>
      <div className="flex items-center text-sm text-slate-300 max-sm:hidden">
        <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
          {" "}
          <div className="bg-[#28453f] ml-4 py-2 px-6 rounded-full cursor-pointer">
            Advertiser
          </div>
        </Link>
        <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
          {" "}
          <div className="bg-[#6aa12d] text-black ml-4 py-2 px-6 rounded-full cursor-pointer">
            Publisher
          </div>
        </Link>
      </div>
    </motion.div>
  );
}

export default Header;
