import React from "react";
import { servicesWeProvide, testimonials } from "../../Data/DataArray";
import { FaFacebook, FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { FaSnapchatSquare } from "react-icons/fa";
import { motion, useScroll } from "framer-motion";
import { FcGoogle } from "react-icons/fc";
import { RxHamburgerMenu } from "react-icons/rx";
import { useTypewriter } from "react-simple-typewriter";
import { BsCursorFill } from "react-icons/bs";
import SocialIcons from "./SocialIcons";
import SMS from "../../Assets/images/sms.png";
import AppDev from "../../Assets/images/appdev.png";
import Media from "../../Assets/images/media.png";
import Content from "../../Assets/images/content.png";
import Seo from "./Seo";
import WebDev from "./WebDev";
import { Link } from "react-router-dom";

function Services() {
  const getWidthClass = (index) => {
    if ((index + 1) % 6 === 2 || (index + 1) % 6 === 3) {
      // Add your custom style for indices 2, 3, 6, 7, 10, 11, etc.
      return "w-full md:w-2/3 lg:w-1/2 xl:w-3/5";
    } else {
      // Add your default style for other indices
      return "w-full md:w-1/3 lg:w-2/5 xl:w-2/5";
    }
  };
  return (
    <div className="mt-20 max-sm:mt-0">
      <div className="pt-0 pb-14 bg-black px-16 w-full max-sm:px-0 max-lg:px-8 max-[900px]:pt-16 max-sm:pt-0">
        <div className="w-full flex flex-col items-center">
          <motion.div
            initial={{
              boxShadow: "0 5px 40px 0px rgba(255,255,255,0.3)",
            }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            whileInView={{
              boxShadow: "0 5px 300px 0px rgba(255,255,255,0.8)",
            }}
            viewport={{
              once: true,
            }}
            className="border border-white bg-white shadow-[0_5px_40px_0px_rgba(0,0,0,0.3)]  shadow-white mb-4 rounded-full text-black py-2 px-7 font-bold max-sm:px-6 max-sm:bg-black max-sm:text-white"
          >
            Services
          </motion.div>
          <motion.div
            initial={{
              color: "black",
              scale: 1,
              transform: "translate(0px, 30px) rotate(10deg)",
            }}
            transition={{
              delay: 0.6,
              duration: 0.1,
            }}
            whileInView={{
              color: "white",
              scale: 1,
              // transform: "translate(10px, 20px)",
              transform: "translate(0px, 0px) rotate(0deg)",
              opacity: 0.9,
            }}
            viewport={{
              once: true,
            }}
            className="capitalize mt-4 text-7xl text-center font-extrabold w-8/12 max-lg:w-11/12 max-sm:w-full max-sm:px-2 max-sm:text-5xl "
          >
            Business Goals Achieved with Design
          </motion.div>
          <motion.div
            initial={{
              color: "black",
              scale: 1,
              transform: "translate(0px, 30px) rotate(10deg)",
            }}
            transition={{
              delay: 0.7,
              duration: 0.1,
            }}
            whileInView={{
              color: "white",
              scale: 1,
              // transform: "translate(10px, 20px)",
              transform: "translate(0px, 0px) rotate(0deg)",
              opacity: 0.9,
            }}
            viewport={{
              once: true,
            }}
            className="w-7/12 text-center text-white/80 mt-6  mb-4 text-lg max-sm:w-full max-sm:text-sm max-sm:px-10 max-lg:mt-8"
          >
            We believe digital marketing is defined by the use of multiple
            channels strategically to connect with consumers to increase brand
            awareness and the conversation ratio.
          </motion.div>
          <motion.div
            initial={{
              color: "black",
              scale: 1,
              transform: "translate(0px, 30px) rotate(10deg)",
            }}
            transition={{
              delay: 0.8,
              duration: 0.1,
            }}
            whileInView={{
              color: "white",
              scale: 1,
              // transform: "translate(10px, 20px)",
              transform: "translate(0px, 0px) rotate(0deg)",
              opacity: 0.9,
            }}
            viewport={{
              once: true,
            }}
            className=" text-white/90 font-semibold mt-4 underline text-lg"
          >
            HERE'S HOW TO DO IT {" >>"}
          </motion.div>
        </div>
      </div>
      <div className="pt-32 pb-20  w-full flex flex-col services max-[900px]:pt-16">
        <div className="flex items-start flex-col w-full px-16 max-sm:px-8 max-lg:px-12">
          <motion.div
            initial={{
              opacity: 0,
              translateX: -100,
            }}
            transition={{
              delay: 0.6,
              duration: 0.3,
              type: "spring",
            }}
            whileInView={{
              opacity: 1,
              translateX: 0,
            }}
            viewport={{
              once: true,
            }}
            className="text-white/60 capitalize"
          >
            Are you looking for help {" >>"}
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
              translateY: 100,
            }}
            transition={{
              delay: 0.8,
              duration: 0.3,
              type: "spring",
            }}
            whileInView={{
              opacity: 1,
              translateY: 0,
            }}
            viewport={{
              once: true,
            }}
            className="text-white text font-bold text-6xl w-2/3 mt-2 max-sm:w-full max-sm:text-4xl"
          >
            Scale your Business with{" "}
            <motion.span
              initial={{
                opacity: 0,
                translateY: 100,
              }}
              transition={{
                delay: 0.9,
                duration: 0.3,
                type: "spring",
              }}
              whileInView={{
                opacity: 1,
                translateY: 0,
              }}
              className="text-[#7ab52e]"
            >
              Addensure
            </motion.span>
          </motion.div>
          <motion.div
            initial={{
              color: "black",
              scale: 1,
              transform: "translate(0px, 30px) rotate(10deg)",
            }}
            transition={{
              delay: 1,
              duration: 0.1,
            }}
            whileInView={{
              color: "white",
              scale: 1,
              // transform: "translate(10px, 20px)",
              transform: "translate(0px, 0px) rotate(0deg)",
              opacity: 0.9,
            }}
            viewport={{
              once: true,
            }}
            className="text-white/70 w-10/12 mt-3 text-lg"
          >
            Addensure Media is your strategic ally for comprehensive digital
            solutions. From website design to SEO, we elevate brands by
            connecting with consumers and boosting sales through dynamic
            campaigns.
          </motion.div>
        </div>
        <div className="flex px-16 flex-wrap max-lg:px-10 max-sm:px-3 mt-16">
          {servicesWeProvide.map((service, index) => {
            return (
              <Link
                key={index}
                to={`in/services/${service.service.split(" ").join("")}`}
                className="w-1/3 p-3 max-[1080px]:w-1/2 max-[770px]:w-full "
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    translateY: 100,
                  }}
                  transition={{
                    delay: 0.9 + parseInt(`0.${index + 2}`),
                    duration: 0.3,
                    type: "spring",
                  }}
                  whileInView={{
                    opacity: 1,
                    translateY: 0,
                  }}
                  viewport={{
                    once: true,
                  }}
                >
                  <div className="border border-white/10 rounded-md h-[400px] w-full max-md:h-auto bg-[#0a0a0a]">
                    <div className="w-full h-3/5 flex items-center justify-center">
                      {index == 0 && (
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-cover object-top rounded-t-md max-md:h-44"
                            src="https://cdn.dribbble.com/users/391742/screenshots/14766886/media/b2ea2774834f112ca1e6a88db4af7d0b.jpg?resize=1200x900&vertical=center"
                            alt="site_image"
                          />
                        </div>
                      )}
                      {index == 1 && (
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-cover object-top rounded-t-md max-md:h-44"
                            src={Content}
                            alt="site_image"
                          />
                        </div>
                      )}
                      {index == 2 && <WebDev />}
                      {index == 3 && (
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-cover object-center rounded-t-md max-md:h-44"
                            src={AppDev}
                            alt="site_image"
                          />
                        </div>
                      )}
                      {index == 4 && <SocialIcons />}
                      {index == 5 && (
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-cover object-top rounded-t-md max-md:h-44"
                            src={Media}
                            alt="site_image"
                          />
                        </div>
                      )}
                      {index == 6 && <Seo />}
                      {index == 7 && (
                        <div className="w-full h-full">
                          <img
                            className="w-full h-full object-cover object-center rounded-t-md max-md:h-44"
                            src={SMS}
                            alt="site_image"
                          />
                        </div>
                      )}
                    </div>
                    <div className="w-full h-2/5 text-white/80 px-6 flex flex-col items-start justify-center py-3">
                      <div className="text-xl font-bold text-white">
                        {service.service}
                      </div>
                      <span className="text-xs text-white/70 mt-1 mb-2">
                        {" "}
                        {service.description.slice(0, 80) + "..."}
                      </span>
                      <div className="bg-[#ffffff] py-2 px-6 text-sm font-semibold  rounded text-black">
                        Read more
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;
