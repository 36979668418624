import React from "react";
import {
  FaCheck,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="w-full flex items-start justify-center px-24 border-t border-t-white/30 pt-16 pb-6 text-white/80 text-base max-[950px]:flex-wrap max-md:px-10">
        <div className="w-1/3 max-[950px]:w-1/2 max-sm:w-full max-sm:mt-8">
          <div className="text-white font-bold text-xl">Company</div>
          <ul className="py-2">
            <Link to="/in/aboutus">
              <li className="my-1 py-2">About</li>
            </Link>
            <Link to="/in/contactus">
              <li className="my-1 py-2">Contact Us</li>
            </Link>
            <Link to="/in/careers">
              <li className="my-1 py-2">Career</li>
            </Link>
            <Link to="/in/blogs">
              <li className="my-1 py-2">Blogs</li>
            </Link>
            <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
              <li className="my-1 py-2">Advertiser</li>
            </Link>
            <Link to="http://addensuremedia.offer18.com/m/" target="_blank">
              <li className="my-1 py-2">Publisher</li>
            </Link>
          </ul>
        </div>
        <div className="w-1/3 max-[950px]:w-1/2 max-sm:w-full max-sm:mt-8">
          <div className="text-white font-bold text-xl">Quick links</div>
          <ul className="py-2">
            <Link to="/in/services/EmailMarketing">
              <li className="my-1 py-2">Email Marketing</li>
            </Link>
            <Link to="/in/services/SearchEngineOptimization">
              <li className="my-1 py-2">SEO</li>
            </Link>
            <Link to="/in/services/ContentCreation">
              <li className="my-1 py-2">Content Creation</li>
            </Link>
            <Link to="/in/services/WebsiteDevelopment">
              <li className="my-1 py-2">Website Development</li>
            </Link>
            <Link to="/in/services/smsmarketing">
              <li className="my-1 py-2">SMS Marketing</li>
            </Link>
            <Link to="/in/services/SocialMediaMarketing">
              <li className="my-1 py-2">Social Media Marketing</li>
            </Link>
            <Link to="/in/services/MediaPlanningandBuying">
              <li className="my-1 py-2">Media Planning and Buying</li>
            </Link>
          </ul>
        </div>
        <div className="w-1/3 max-[950px]:w-full max-sm:mt-8">
          <div className="">
            <Link to="/">
              <div>
                <img
                  src="https://www.addensure.com/images/media/logo.png"
                  alt="addenure Logo"
                  className="w-36"
                />
              </div>
            </Link>
            <div className="text-white pt-6">
              <div className="">
                <Link
                  to="https://www.facebook.com/profile.php?id=61555026593036"
                  target="_blank"
                >
                  <div className="flex items-start mr-4 py-2 my-2">
                    <FaFacebookF fontSize={18} />
                    <span className="mx-2 underline">Facebook</span>
                  </div>
                </Link>
                <Link to="https://twitter.com/addensuremedia" target="_blank">
                  <div className="flex items-start mr-4 py-2 my-2">
                    <FaTwitter fontSize={18} />
                    <span className="mx-2 underline">Twitter</span>
                  </div>
                </Link>
                <Link
                  to="https://www.instagram.com/addensuremedia/"
                  target="_blank"
                >
                  <div className="flex items-start mr-4 py-2 my-2">
                    <FaInstagram fontSize={18} />
                    <span className="mx-2 underline">Instagram</span>
                  </div>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/addensure/"
                  target="_blank"
                >
                  <div className="flex items-start mr-4 py-2 my-2">
                    <FaLinkedinIn fontSize={18} />
                    <span className="mx-2 underline">LinkedIn</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="pt-2">
              <div className="w-full py-8 text-white/50 text-xs flex items-center justify-between">
                <div>
                  Copyright © 2015-24 Addensure Media LLP . All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
